import React, { useState } from 'react';
import TabSelection from './TabSelection';
import SideBar from '../../../components/AdminUserSideBar';
import AlexUsage from './AlexUsage';

const tabs = ['Alex Usage'];

const Tabs = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filter, setFilter] = useState({});
  const [currentTab, setCurrentTab] = useState('Alex Usage');
  const [openSideActionBoard, setOpenSideActionBoard] = useState(false);

  return (
    <div>
      {openSideActionBoard && (
        <SideBar
          openSideActionBoard={openSideActionBoard}
          setOpenSideActionBoard={setOpenSideActionBoard}
        />
      )}
      <TabSelection
        tabs={tabs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filter={filter}
        setFilter={setFilter}
      />
      <div style={{ gap: '64px' }} className="flex flex-col">
        {(currentTab === 'Alex Usage' || searchQuery !== '') && (
          <AlexUsage searchQuery={searchQuery} filter={filter} />
        )}
      </div>
    </div>
  );
};

export default Tabs;
