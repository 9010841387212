import React, { useState } from 'react';
import { useQuery } from 'react-query';
import SVG from 'react-inlinesvg';
import Pagination from '../../../components/Pagination';
import Table from '../../../components/Table';
import { convertToDollarString, formatDate } from '../../../helpers';

import { adminAPI } from '../../../api';

import EditIcon from '../../../../../../assets/images/reskin-images/icon--edit.svg';
import EyeIcon from '../../../../../../assets/images/reskin-images/icon--eye.svg';
import BinIcon from '../../../../../../assets/images/reskin-images/icon--bin.svg';
import SendIcon from '../../../../../../assets/images/reskin-images/icon--send.svg';
import useModal from '../../../context/ModalContext';
import DestroyModal from './DestroyModal';
import ResendInviteModal from './ResendInviteModal';

const PendingInvitesTable = ({
  searchQuery,
  filter,
  pendingInvites,
  setPendingInvites,
  setOpenSideActionBoard,
}) => {
  const [currentPage, setCurrentPage] = useState(
    data?.pagination?.current_page || 1
  );
  const { data, isLoading } = useQuery(
    ['pendingInvites', { page: currentPage, search: searchQuery, filter }],
    adminAPI.getUserInvites
  );
  const columns = [
    {
      label: 'Email',
      field: 'email',
      textAlign: 'left',
      fontWeight: 'semibold',
      width: '40%',
    },
    {
      label: 'Domain',
      field: 'domain',
      textAlign: 'left',
      fontWeight: 'semibold',
      width: '15%',
    },
    {
      label: 'Sent',
      field: 'sent',
      textAlign: 'center',
      width: '15%',
    },
    {
      label: 'Expires',
      field: 'expires',
      textAlign: 'center',
      width: '15%',
    },
    { label: 'Discount', field: 'discount', textAlign: 'center', width: '15%' },
  ];

  const [rowHovered, setRowHovered] = useState(false);

  const reconstructorFn = (userInvite) => {
    const {
      id,
      email,
      domain,
      invited_at: invitedAt,
      expires_at: expiresAt,
      discounted_cents: discountedCents,
    } = userInvite;

    const actionButtons = (
      <div
        className={` absolute pin-r pin-t h-full items-center pr-2 ${
          rowHovered === id ? 'flex' : 'hidden'
        }`}
      >
        <button
          type="button"
          className="flex items-center justify-content-center bg-purple-100 p-2 rounded-lg mr-2 hover:bg-purple-200"
          onClick={() =>
            setOpenSideActionBoard({
              type: 'Pending Invites',
              action: 'view',
              ids: [id],
            })
          }
        >
          <SVG src={EyeIcon} />
        </button>
        <button
          type="button"
          className="flex items-center justify-content-center bg-purple-100 p-2 rounded-lg hover:bg-purple-200"
          onClick={() =>
            setOpenSideActionBoard({
              type: 'Pending Invites',
              action: 'edit',
              ids: [id],
            })
          }
        >
          <SVG src={EditIcon} />
        </button>
      </div>
    );

    return {
      id,
      email,
      domain,
      sent: formatDate(invitedAt),
      expires: expiresAt ? formatDate(expiresAt) : '---',
      discount: convertToDollarString(discountedCents),
    };
  };

  const { setContent } = useModal();

  return (
    <div>
      <div className="mb-4 ml-10 flex items-center">
        <h2 className="font-semibold">Pending Invites</h2>
        {pendingInvites?.length ? (
          <button
            onClick={() =>
              setContent({
                body: (
                  <ResendInviteModal
                    items={pendingInvites}
                    setItems={setPendingInvites}
                  />
                ),
              })
            }
            type="button"
            className="flex items-center font-semibold bg-purple-100 rounded-lg py-3 px-4 ml-8 hover:bg-purple-lightest"
          >
            <SVG src={SendIcon} className="mr-2" />
            Resend {pendingInvites.length} Selected Invites
          </button>
        ) : null}
        {pendingInvites?.length ? (
          <button
            type="button"
            className="flex items-center font-semibold hover:bg-red-lighter rounded-lg p-3 ml-4"
            onClick={() => {
              setContent({
                body: (
                  <DestroyModal
                    items={pendingInvites}
                    type="invites"
                    queryListId="pendingInvites"
                    setItems={setPendingInvites}
                  />
                ),
              });
            }}
          >
            <SVG src={BinIcon} />
          </button>
        ) : null}
      </div>

      {!isLoading && (
        <Table
          id="unconfirmed_users"
          data={data.user_invites}
          columns={columns}
          reconstructorFn={reconstructorFn}
          checkedItems={pendingInvites}
          setCheckedItems={setPendingInvites}
          setRowHovered={setRowHovered}
        />
      )}
      <Pagination
        currentPage={currentPage}
        totalPage={data?.pagination?.total_pages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default PendingInvitesTable;
