import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import FormInput from '../../../../components/FormInput';
import Button from '../../../../components/Button';
import { adminAPI } from '../../../../api';

const blankUser = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
};

const UserForm = ({ user, onSubmitForm, onCloseForm, errors }) => {
  const { slug } = useParams();

  const formattedUser = user && {
    firstName: user.profile.first_name,
    lastName: user.profile.last_name,
    email: user.email,
    phone: user.profile.phone,
  };
  const [formData, setFormData] = useState(formattedUser || blankUser);
  const handleFormChange = useCallback(
    (key, value) => {
      setFormData((currentValue) => ({
        ...currentValue,
        [key]: value,
      }));
    },
    [formData]
  );

  const handleFormSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onSubmitForm(formData);
    },
    [formData]
  );

  const handlePasswordReset = useCallback(() => {
    adminAPI.sendUserEmail({ user: user.id, slug });
    onCloseForm();
  }, [user, slug]);

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="pb-4" style={{ width: '300px' }}>
        <FormInput
          label="First Name"
          name="firstName"
          value={formData.firstName}
          type="text"
          handleChange={(e) => handleFormChange('firstName', e.target.value)}
        />
      </div>
      <div className="pb-4" style={{ width: '300px' }}>
        <FormInput
          label="Last Name"
          name="lastName"
          value={formData.lastName}
          type="text"
          handleChange={(e) => handleFormChange('lastName', e.target.value)}
        />
      </div>
      <div className="pb-4" style={{ width: '300px' }}>
        <FormInput
          label="Email *"
          name="email"
          value={formData.email}
          type="email"
          handleChange={(e) => handleFormChange('email', e.target.value)}
        />
      </div>
      <div className="pb-4" style={{ width: '300px' }}>
        <FormInput
          label="Phone"
          name="phone"
          value={formData.phone}
          type="text"
          handleChange={(e) => handleFormChange('phone', e.target.value)}
        />
      </div>

      {errors && (
        <ul className="list-disc pb-4">
          {errors.map((error) => (
            <li className="text-red">{error}</li>
          ))}
        </ul>
      )}
      <div className="flex flex-row items-center">
        <Button
          type="submit"
          variant="default-lowercase"
          className="font-bold capitalize"
          style={{ borderRadius: '12px' }}
        >
          {`${user ? 'Edit' : 'Create'} User`}
        </Button>
        {user && (
          <div onClick={handlePasswordReset}>
            <p className="pl-4 underline underline-offset-4 cursor-pointer">
              Send Password Email
            </p>
          </div>
        )}
      </div>
    </form>
  );
};

export default UserForm;
