import { apiFetch } from './apiFetch';

export const getUsersCompanies = () =>
  apiFetch({
    url: '/api/v2/admin/user_profile/companies',
    method: 'GET',
  });

export const getUsers = ({ queryKey }) => {
  const [, { page, search, filter, confirmed, count }] = queryKey;
  return apiFetch({
    url: '/api/v2/admin/users/users',
    method: 'POST',
    body: { page, search, filter, confirmed, count },
  });
};

export const getUserInvites = ({ queryKey }) => {
  const [, { page, search, filter }] = queryKey;
  return apiFetch({
    url: '/api/v2/admin/users/user_invites',
    method: 'POST',
    body: { page, search, filter },
  });
};

export const updateUser = (serializedData) =>
  apiFetch({
    url: '/api/v2/admin/users/update_user',
    method: 'POST',
    body: serializedData,
  });

export const inviteUsers = (serializedData) =>
  apiFetch({
    url: '/api/v2/admin/users/invite_users',
    method: 'POST',
    body: serializedData,
  });

export const getInvitationData = () =>
  apiFetch({
    url: '/api/v2/admin/users/invitation_data',
  });

export const destroyInvites = (serializedData) =>
  apiFetch({
    url: '/api/v2/admin/users/destroy_invites',
    method: 'POST',
    body: serializedData,
  });

export const destroyUsers = (serializedData) =>
  apiFetch({
    url: '/api/v2/admin/users/destroy_users',
    method: 'POST',
    body: serializedData,
  });

export const destroyInvitationOrUsers = (serializedData, type) => {
  if (type === 'users') {
    return destroyUsers(serializedData);
  }
  if (type === 'invites') {
    return destroyInvites(serializedData);
  }
};

export const resendInvites = (serializedData) =>
  apiFetch({
    url: '/api/v2/admin/users/resend_invites',
    method: 'POST',
    body: serializedData,
  });

export function getBehaviors() {
  return apiFetch({
    url: '/api/v2/admin/behaviors/get_behaviors',
  });
}

export function getBehavior({ behaviorID }) {
  return apiFetch({
    url: `/api/v2/admin/behaviors/${behaviorID}.json`,
    method: 'GET',
  });
}

export async function updateBehavior({
  data: curriculum_behavior,
  behaviorID,
}) {
  return apiFetch({
    url: `/api/v2/admin/behaviors/${behaviorID}`,
    method: 'PATCH',
    body: {
      curriculum_behavior,
    },
  });
}

export function updateHabitOrder(habits) {
  return apiFetch({
    url: '/api/v2/admin/help_to_habits/update_order',
    method: 'POST',
    body: {
      help_to_habit: {
        reorder: habits,
      },
    },
  });
}

export async function getCourses() {
  return apiFetch({
    url: '/api/v2/admin/courses/get_courses',
  });
}

export async function createNew({ data, behaviorID }) {
  return apiFetch({
    url: '/api/v2/admin/help_to_habits/create_new',
    method: 'POST',
    body: {
      data,
      behavior_id: behaviorID,
    },
  });
}

export async function deleteHelpToHabit({ helpToHabitID }) {
  return apiFetch({
    url: '/api/v2/admin/help_to_habits/delete_help_to_habit',
    method: 'POST',
    body: {
      help_to_habit_id: helpToHabitID,
    },
  });
}

export async function editHelpToHabit({ data: help_to_habit, helpToHabitID }) {
  return apiFetch({
    url: `/api/v2/admin/help_to_habits/${helpToHabitID}`,
    method: 'PATCH',
    body: {
      help_to_habit,
    },
  });
}

export async function editHelpToHabitExtra({
  data: help_to_habit_extra,
  behaviorId,
  extraId,
}) {
  const method = extraId ? 'PATCH' : 'POST';
  let url = `/api/v2/admin/behaviors/${behaviorId}/help_to_habit_extras`;
  if (extraId) {
    url = `${url}/${extraId}`;
  }
  return apiFetch({
    url,
    method,
    body: {
      help_to_habit_extra,
    },
  });
}

export async function createTenant(data) {
  data.subdomain = data.slug;
  return apiFetch({
    url: '/api/v2/admin/tenants',
    method: 'POST',
    body: {
      ...data,
    },
  });
}

export async function editTenant(data) {
  data.subdomain = data.slug;
  delete data.slug;
  return apiFetch({
    url: `/api/v2/admin/tenants/${data.id}`,
    method: 'PUT',
    body: {
      ...data,
    },
  });
}

export async function getTenants() {
  return apiFetch({
    url: '/api/v2/admin/tenants',
    method: 'GET',
  });
}

export async function getTenantUserAdmins({ queryKey }) {
  return apiFetch({
    url: `/api/v2/admin/tenants/slug/${queryKey[1]}/admins`,
    method: 'GET',
  });
}

export async function getTenantBySlug({ queryKey }) {
  return apiFetch({
    url: `/api/v2/admin/tenants/slug/${queryKey[0]}`,
    method: 'GET',
  });
}

export async function createTenantUserAdmin({ data, slug }) {
  return apiFetch({
    url: `/api/v2/admin/tenants/slug/${slug}/admin`,
    method: 'POST',
    body: {
      email: data.email,
      profile_attributes: {
        first_name: data.firstName,
        last_name: data.lastName,
        phone: data.phone,
      },
    },
  });
}

export async function editTenantUserAdmin({ data, slug }) {
  return apiFetch({
    url: `/api/v2/admin/tenants/slug/${slug}/admin`,
    method: 'PUT',
    body: {
      email: data.email,
      profile_attributes: {
        first_name: data.firstName,
        last_name: data.lastName,
        phone: data.phone,
      },
    },
  });
}

export async function getTenantUsers({ queryKey }) {
  return apiFetch({
    url: `/api/v2/admin/tenants/slug/${queryKey[1]}/users`,
    method: 'GET',
  });
}

export async function createTenantUser({ data, slug }) {
  return apiFetch({
    url: `/api/v2/admin/tenants/slug/${slug}/user`,
    method: 'POST',
    body: {
      email: data.email,
      profile_attributes: {
        first_name: data.firstName,
        last_name: data.lastName,
        phone: data.phone,
      },
    },
  });
}

export async function editTenantUser({ data, slug }) {
  return apiFetch({
    url: `/api/v2/admin/tenants/slug/${slug}/user`,
    method: 'PUT',
    body: {
      email: data.email,
      profile_attributes: {
        first_name: data.firstName,
        last_name: data.lastName,
        phone: data.phone,
      },
    },
  });
}

export async function createTenantUserBulk({ data, slug }) {
  const formData = new FormData();
  formData.append('csv_file', data.file);
  if (data.group) {
    formData.append('group', data.group);
  }

  return apiFetch({
    url: `/api/v2/admin/tenants/slug/${slug}/user/bulk`,
    method: 'POST',
    body: formData,
    stringify: false,
    getHeaders: (headers) => {
      delete headers['Content-Type'];
      return headers;
    },
  });
}

export function getTenantGroups({ queryKey }) {
  return apiFetch({
    url: `/api/v2/admin/tenants/slug/${queryKey[1]}/groups/available`,
    method: 'GET',
  });
}

export async function activateTenantGroup({ group, slug }) {
  return apiFetch({
    url: `/api/v2/admin/tenants/slug/${slug}/group/${group.id}/activate`,
    method: 'POST',
  });
}

export async function enableTenantBehavior({ course, behavior, slug }) {
  return apiFetch({
    url: `/api/v2/admin/tenants/slug/${slug}/${course}/${behavior}/enable`,
    method: 'POST',
  });
}

export async function disableTenantBehavior({ course, behavior, slug }) {
  return apiFetch({
    url: `/api/v2/admin/tenants/slug/${slug}/${course}/${behavior}/disable`,
    method: 'POST',
  });
}

export async function sendUserEmail({ user, slug }) {
  return apiFetch({
    url: `/api/v2/admin/tenants/slug/${slug}/user/${user}/send_email`,
    method: 'POST',
  });
}

export async function sendTenantUserInvites({ slug, data, emails }) {
  const expirationDate = new Date();
  if (data.expirationType === 'days') {
    expirationDate.setDate(expirationDate.getDate() + data.expirationLength);
  } else if (data.expirationType === 'years') {
    expirationDate.setDate(expirationDate.getDate() + data.expirationLength);
  }
  return apiFetch({
    url: `/api/v2/admin/tenants/slug/${slug}/user/invites`,
    method: 'POST',
    body: {
      emails,
      access_type: data.limitedAccess,
      expiration_type: data.fixedExpirationDate ? 'length' : 'date',
      expires_at: data.fixedExpirationDate
        ? data.expirationDate
        : expirationDate,
      skip_email: data.skipEmail,
    },
  });
}

export async function resendTenantUserInvites({ slug, emails }) {
  return apiFetch({
    url: `/api/v2/admin/tenants/slug/${slug}/user/invites/resend`,
    method: 'POST',
    body: {
      emails,
    },
  });
}

export async function getTenantUserInvites({ queryKey }) {
  return apiFetch({
    url: `/api/v2/admin/tenants/slug/${queryKey[1]}/user/invites`,
    method: 'GET',
  });
}

//alex
export function sendMessage({ message, sessionId }) {
  return apiFetch({
    url: '/api/v2/alex/message',
    method: 'POST',
    body: {
      message,
      session_id: sessionId,
    },
  });
}

export function getConversations(offset) {
  return apiFetch({
    url: `/api/v2/alex/conversations?offset=${offset}`,
    method: 'GET',
  });
}

export function getConversation(id) {
  return apiFetch({
    url: `/api/v2/alex/conversations/${id}`,
    method: 'GET',
  });
}

export function editConversationTitle(id, title) {
  return apiFetch({
    url: `/api/v2/alex/${id}`,
    method: 'PUT',
    body: {
      title,
    },
  });
}

export function deleteConversation(id) {
  return apiFetch({
    url: `/api/v2/alex/${id}`,
    method: 'DELETE',
  });
}

export function sendMessageFeedback({ liked, feedback, message_id }) {
  return apiFetch({
    url: '/api/v2/conversation_message_feedbacks/toggle',
    method: 'POST',
    body: {
      liked,
      feedback,
      message_id,
    },
  });
}

export function deleteMessageFeedback({ message_id }) {
  return apiFetch({
    url: '/api/v2/conversation_message_feedbacks/delete',
    method: 'DELETE',
    body: {
      message_id,
    },
  });
}

export async function getReports({ queryKey }) {
  const [, { page = 1, search, filter, count = 10 }] = queryKey;

  const url = new URL('/api/v2/admin/reports', window.location.origin);
  const params = {
    page,
    count,
    ...(search && { search }),
    ...(filter &&
      Object.keys(filter).length > 0 && {
        filter: JSON.stringify(filter),
      }),
  };

  url.search = new URLSearchParams(params).toString();

  return apiFetch({
    url: url.toString(),
    method: 'GET',
  });
}
